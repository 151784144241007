<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-body>
      <div class="transaction-item">

        <font-awesome-icon
          :icon="data.icon"
          size="3x"
          :style="{ color: '#7367f0' }"
        />
        <div class="text-right">
          <div>
            <span class="text-primary mr-1">2.345</span>
            <span>Total</span>
          </div>
          <div>
            <span class="text-success mr-1">2.300</span>
            <span>Active</span>
          </div>
          <div>
            <span class="text-danger mr-1">45</span>
            <span>Perawatan</span>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard,BCardBody,} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
